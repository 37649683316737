import styled from 'styled-components'
import { Box } from 'styles/globalStyle'

export const FormCard = styled(Box)`
  width: calc(90% - 3rem);
  height: calc(90% - 3rem);
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  padding: 1.5rem;
  overflow-y: auto;
  border-radius: 0.75rem;

  @media screen and (min-width: ${({ theme }) => theme.bp.xs}) {
    height: auto;
  }

  @media screen and (min-width: ${({ theme }) => theme.bp.md}) {
    width: calc(838px - 3rem);
    height: 820px;
  }
`
