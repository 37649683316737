import React, { FC, useState } from 'react'
import { renderStars } from 'helpers/renderStars'
import { Box, Circle, Flex } from 'styles/globalStyle'
import { ProductsFeaturesType } from 'types/brandPageTypes'
import { Icons, Text } from './theme'
import { IconsName } from './theme/Icons'
import { AnimatePresence, motion } from 'framer-motion'

type ProductFeaturesParams = {
  list: ProductsFeaturesType
}

const ProductFeatures: FC<ProductFeaturesParams> = ({ list }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const data = [
    { icon: 'tag', text: `Type : ${list.type.text}` },
    { icon: 'flag', text: `Classe : ${list.class.text}` },
    {
      icon: 'volume',
      text: `Réduction de bruit active :`,
      rating: list.active_noise_reduction,
    },
    {
      icon: 'user',
      text: 'Retour client :',
      rating: list.feedback_client,
    },
    {
      icon: 'award',
      text: 'Note Audiplus :',
      rating: list.rating_audiplus,
    },
  ]
  const dataPlus = [
    { icon: 'headphones', text: `Perte Auditive : ${list.hearing_loss.text}` },
    { icon: 'droplet', text: `Étanche : ${list.etanche.text}` },
    {
      icon: 'volume',
      text: 'Réduction d’acouphène :',
      rating: list.tinnitus_reduction,
    },
    {
      icon: 'shopping-cart',
      text: `Disponibilité : ${list.availability.text}`,
    },
  ]

  return (
    <Box flex="1">
      {data.map((item, idx) => (
        <Flex key={idx} mb="1rem">
          <Circle bg="primary.25" width="1.5rem" height="1.5rem">
            <Icons
              name={item.icon as IconsName}
              width="0.75rem"
              height="0.75rem"
              color="primary.600"
            />
          </Circle>
          <Flex alignItems="center">
            <Text ml="0.75rem">{item.text}</Text>
            {item.rating && renderStars(item.rating)}
          </Flex>
        </Flex>
      ))}
      <Flex
        onClick={() => setExpanded((prevState) => !prevState)}
        style={{ cursor: 'pointer' }}
      >
        <Icons
          name={expanded ? 'arrow-down' : 'chevron-right'}
          width="1.25rem"
          height="1.25rem"
          color="primary.700"
        />
        <Text size="sm" color="primary.700" fontWeight="500" ml="0.5rem">
          Plus de caractéristiques
        </Text>
      </Flex>
      <AnimatePresence initial={false}>
        <motion.div
          animate={expanded ? 'open' : 'closed'}
          variants={{
            open: { height: 'auto', opacity: 1, pointerEvents: 'unset' },
            closed: { height: 0, opacity: 0, pointerEvents: 'none' },
          }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          <Box pt="1rem">
            {dataPlus.map((item, idx) => (
              <Flex key={idx} mb="1rem">
                <Circle bg="primary.25" width="1.5rem" height="1.5rem">
                  <Icons
                    name={item.icon as IconsName}
                    width="0.75rem"
                    height="0.75rem"
                    color="primary.600"
                  />
                </Circle>
                <Flex alignItems="center">
                  <Text ml="0.75rem">{item.text}</Text>
                  {item.rating && renderStars(item.rating)}
                </Flex>
              </Flex>
            ))}
          </Box>
        </motion.div>
      </AnimatePresence>
    </Box>
  )
}

export default ProductFeatures
