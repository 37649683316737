import React, { FC, Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Portal } from '@headlessui/react'
import { AnimatePresence } from 'framer-motion'
import { Button, Checkbox, Grid, Input, Text } from '../theme'
import { DialogOverlay, StyledDialog } from 'styles/globalStyle'
import { FormCard } from './styled'
import { DevisData, ProductsFeaturesType } from 'types/brandPageTypes'
import CentersSelect from 'components/CentersSelect'
import { sendEmail } from 'helpers/http'

type FormDevisParams = {
  product: ProductsFeaturesType
}

const FormDevis: FC<FormDevisParams> = ({ product }) => {
  const [isLoading, setLoading] = useState(false)
  const [showSuccess, setSuccess] = useState(false)
  const [showError, setError] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      lastName: '',
      firstName: '',
      email: '',
      phoneNumber: '',
      center: '',
      centerName: '',
      birthDate: '',
      socialSecurity: '',
      mutualInsurance: '',
      mutualName: '',
      mutualPhone: '',
      // conditions: false,
    },
  })
  const submitForm = async (data: DevisData) => {
    setLoading(true)
    const postData = {
      TemplateId: 3939981,
      DestinationInfo: {
        Email: data.center,
        Name: `${data.firstName} ${data.lastName}`,
      },
      Variables: {
        customer: `${data.firstName} ${data.lastName}`,
        product: product.name.text,
        lastname: data.lastName,
        firstname: data.firstName,
        email: data.email,
        birthdate: data.birthDate,
        socialsecurity: data.socialSecurity,
        mutualinsurance: data.mutualInsurance,
        mutualname: data.mutualName,
        mutualphone: data.mutualPhone,
        centername: data.centerName,
      },
    }

    const emailReq = await sendEmail(postData)

    if (emailReq && emailReq.data.isSuccessStatusCode) {
      setSuccess(true)
    } else {
      setError(true)
    }

    setLoading(false)
  }

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'

    return () => {
      setSuccess(false)
      setError(false)
    }
  }, [isOpen])

  return (
    <Fragment>
      <Button type="light" size="xl" onClick={() => setIsOpen(true)}>
        Demander un devis
      </Button>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <Portal>
            <StyledDialog
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <DialogOverlay onClick={() => setIsOpen(false)} />
              <FormCard>
                <Text size="lg" fontWeight="500" color="gray.900">
                  Demander un devis pour l’appareil {product.name.text}
                </Text>
                <Text size="sm" mt="0.5rem" mb="1.25rem">
                  En remplissant le formulaire ci-dessous, vous recevrez un
                  devis détaillé pour cet appareil où figurera le montant de vos
                  remboursements Sécurité sociale et mutuelle (à condition de
                  remplir les champs concernant votre mutuelle pour que nous
                  nous puissions les contacter afin d’obtenir le montant de vos
                  remboursements).
                </Text>
                <form onSubmit={handleSubmit(submitForm)}>
                  <Grid
                    columns={['1fr', 'repeat(2, 1fr)']}
                    spacing="0.75rem"
                    mb="1.25rem"
                  >
                    <Input
                      label="Nom"
                      register={register}
                      value="lastName"
                      placeholder="Entrer votre nom"
                      errors={errors}
                      required
                    />
                    <Input
                      label="Prénom"
                      register={register}
                      value="firstName"
                      placeholder="Entrer votre prénom"
                      errors={errors}
                      required
                    />
                  </Grid>
                  <Grid
                    columns={['1fr', 'repeat(2, 1fr)']}
                    spacing="0.75rem"
                    mb="1.25rem"
                  >
                    <Input
                      type="email"
                      label="Email"
                      register={register}
                      value="email"
                      placeholder="Entrer votre adresse email"
                      errors={errors}
                      required
                    />
                    <Input
                      type="tel"
                      label="Téléphone"
                      register={register}
                      value="phoneNumber"
                      placeholder="Entrer votre numéro de téléphone"
                      errors={errors}
                      required
                    />
                  </Grid>
                  <CentersSelect
                    register={register}
                    errors={errors}
                    setValue={setValue}
                  />
                  <Text
                    size="lg"
                    color="gray.900"
                    my="1.25rem"
                    fontWeight="500"
                  >
                    Informations pour obtenir vos remboursements (falcutatif)
                  </Text>
                  <Input
                    label="Date de naissance"
                    register={register}
                    value="birthDate"
                    placeholder="JJ/MM/YYYY"
                    errors={errors}
                  />
                  <Grid
                    columns={['1fr', 'repeat(2, 1fr)']}
                    spacing="0.75rem"
                    my="1.25rem"
                  >
                    <Input
                      label="N° de sécurité sociale"
                      register={register}
                      value="socialSecurity"
                      placeholder="Entrer votre numéro de sécurité sociale"
                      errors={errors}
                    />
                    <Input
                      label="N° d’adhérent mutuelle"
                      register={register}
                      value="mutualInsurance"
                      placeholder="Entrer votre numéro d’adhérent mutuelle"
                      errors={errors}
                    />
                    <Input
                      label="Nom de votre mutuelle"
                      register={register}
                      value="mutualName"
                      placeholder="Entrer le nom de votre mutuelle"
                      errors={errors}
                    />
                    <Input
                      label="N° de tél de votre mutuelle"
                      register={register}
                      value="mutualPhone"
                      placeholder="Entrer le numéro de tél de votre mutuelle"
                      errors={errors}
                    />
                  </Grid>
                  {/* <Checkbox
                    label="J'accepte les conditions générales d'utilisation"
                    register={register}
                    errors={errors}
                    value="conditions"
                    required
                  /> */}
                  <Grid
                    columns={['1fr', 'repeat(2, 1fr)']}
                    spacing="1.25rem"
                    mt="1rem"
                  >
                    <Button
                      type="light"
                      onClick={(e) => {
                        e.preventDefault()
                        reset()
                        setIsOpen(false)
                      }}
                    >
                      Annuler
                    </Button>
                    <Button type="gradient" loading={isLoading}>
                      Valider ma demande
                    </Button>
                  </Grid>
                </form>
                {showSuccess && (
                  <Text mt="1rem" color="primary.700" fontWeight="500">
                    Votre demande a bien été reçu par nos équipes, nous vous
                    recontacterons très rapidement
                  </Text>
                )}
                {showError && (
                  <Text mt="1rem" color="error.500" fontWeight="500">
                    Une erreur est survenue. Veuillez réesayer dans quelques
                    instants.
                  </Text>
                )}
              </FormCard>
            </StyledDialog>
          </Portal>
        )}
      </AnimatePresence>
    </Fragment>
  )
}
export default FormDevis
