import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { AnimatePresence, motion } from 'framer-motion'
import { BrandsPageTypes } from 'types/brandPageTypes'
import { Box, Card, Container, Flex } from 'styles/globalStyle'
import { Title, Text, Grid, Button } from 'components/theme'
import Layout from 'components/Layout'
import SliceSection from 'components/SliceSection'
import ProductFeatures from 'components/ProductFeatures'
import Pagination from 'components/Pagination'
import FormDevis from 'components/FormDevis'
import SEO from 'components/SEO'

type BrandsPageParams = {
  data: BrandsPageTypes
}

const BrandsPage: FC<BrandsPageParams> = ({ data }) => {
  const products = data.products.nodes
  const uidBrand = data.prismicProductBrands.uid
  const { title_meta, description_meta, name, body, image } =
    data.prismicProductBrands.data

  return (
    <Layout>
      <SEO title={title_meta.text} description={description_meta.text} />
      <Container mt={['4rem', '6rem']}>
        <Text color="primary.700" mb="0.75rem" fontWeight="600">
          Découvrez maintenant
        </Text>
        <Title size="md">
          Notre sélection des meilleurs appareils auditifs {name.text}
        </Title>
        {/* <Box mt="2rem" width="384px" position="relative">
          <Text size="sm" mb="0.375rem" fontWeight="500" color="gray.700">
            Choississez votre marque parmi nos sélections
          </Text>
          <Select
            options={brands}
            placeholder="Sélectionner une marque"
            value={currentBrand}
            onChange={filterBrand}
          />
        </Box> */}
        <Grid
          columns={['1fr', 'repeat(2, 1fr)', '', '', '', 'repeat(3, 1fr)']}
          spacing="4rem 2rem"
          mt="5rem"
          mb="3rem"
          alignItems="flex-start"
        >
          <AnimatePresence>
            {products.map((product, idx) => (
              <motion.div
                key={idx}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 10, opacity: 0 }}
                transition={{ duration: 0.4, delay: idx * 0.2 }}
              >
                <Card
                  padding="2rem 2rem 2.5rem"
                  position="relative"
                  border="1px solid"
                  borderColor="gray.200"
                  display="flex"
                  flexDirection="column"
                >
                  <Box position="absolute" top="-3rem" right="0">
                    <GatsbyImage
                      alt={`${product.data.name.text} image`}
                      image={product.data.product_image.gatsbyImageData}
                      style={{ maxHeight: '146px', maxWidth: '160px' }}
                      objectFit="contain"
                    />
                  </Box>
                  <Box mb="0.5rem">
                    <GatsbyImage
                      alt={image.alt}
                      image={image.gatsbyImageData}
                      style={{ height: 'auto', maxWidth: '94px' }}
                    />
                  </Box>
                  <Text
                    size="xl"
                    fontWeight="600"
                    color="gray.900"
                    mr="6rem"
                    mb="1.5rem"
                  >
                    {product.data.name.text}
                  </Text>
                  <ProductFeatures list={product.data} />
                  <Flex flexDirection="column" mt="2rem">
                    <Button
                      type="gradient"
                      size="xl"
                      to="/appointment"
                      mb="0.75rem"
                    >
                      Essayer gratuitement
                    </Button>
                    <FormDevis product={product.data} />
                  </Flex>
                </Card>
              </motion.div>
            ))}
          </AnimatePresence>
        </Grid>
        <Pagination uid={uidBrand} pageInfo={data.products.pageInfo} />
      </Container>
      {body.map((item, idx) => (
        <SliceSection key={idx} data={item} />
      ))}
    </Layout>
  )
}

export const query = graphql`
  query ($uid: String, $limit: Int!, $skip: Int!) {
    prismicProductBrands(uid: { eq: $uid }) {
      uid
      data {
        ...PrismicBrandsPageDataTypeFragment
      }
    }
    products: allPrismicProducts(
      filter: { data: { brand: { uid: { eq: $uid } } } }
      limit: $limit
      skip: $skip
      sort: { fields: data___rating_audiplus, order: DESC }
    ) {
      nodes {
        data {
          ...PrismicBrandsProductTypeFragment
        }
      }
      pageInfo {
        currentPage
        pageCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export default BrandsPage
