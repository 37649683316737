import styled from 'styled-components'
import { Box } from 'styles/globalStyle'

export const PaginationContainer = styled(Box)`
  button span {
    display: none;
  }
  .pagination {
    display: flex;
    justify-content: center;
    .previous {
      flex: 1;
    }
    .next {
      flex: 1;
      text-align: right;
    }
    li:not(.next, .previous) {
      &.selected a {
        background: ${({ theme }) => theme.colors.primary[50]};
        color: ${({ theme }) => theme.colors.primary[600]};
      }
      &:not(:last-child) {
        margin-right: 0.25rem;
      }
      a {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.gray[500]};
        transition: 0.3s;
        &:hover {
          background: ${({ theme }) => theme.colors.primary[25]};
        }
      }
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.bp.xs}) {
    button span {
      display: block;
    }
  }
`
