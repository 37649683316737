import React, { FC } from 'react'
import { navigate } from 'gatsby'
import ReactPaginate from 'react-paginate'
import { Button, Icons } from '../theme'
import { PageInfoType } from 'types/dataTypes'
import { PaginationContainer } from './styled'

type PaginationParams = {
  uid: string
  pageInfo: PageInfoType
}

const Pagination: FC<PaginationParams> = ({ uid, pageInfo }) => {
  const { pageCount, currentPage, hasNextPage, hasPreviousPage } = pageInfo
  const handlePageClick = (event: { selected: number }) => {
    if (event.selected === 0) {
      navigate(`/brand/${uid}`)
    } else {
      navigate(`/brand/${uid}/${event.selected + 1}`)
    }
  }
  const nextPage = () => {
    if (hasNextPage) {
      navigate(`/brand/${uid}/${currentPage + 1}`)
    }
  }
  const prevPage = () => {
    if (hasPreviousPage) {
      currentPage === 2
        ? navigate(`/brand/${uid}`)
        : navigate(`/brand/${uid}/${currentPage - 1}`)
    }
  }

  return (
    <PaginationContainer
      alignItems="center"
      justifyContent="space-between"
      mb="4.75rem"
    >
      <ReactPaginate
        breakLabel="..."
        onPageChange={handlePageClick}
        className="pagination"
        pageCount={pageCount}
        initialPage={currentPage - 1}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        // hrefBuilder={(selectedPage) => {
        //   return selectedPage === 1
        //     ? `/brand/${uid}`
        //     : `/brand/${uid}/${selectedPage}`
        // }}
        previousLabel={
          <Button
            type="light"
            size="sm"
            onClick={prevPage}
            disabled={!hasPreviousPage}
          >
            <Icons
              name="arrow-left"
              width="0.75rem"
              height="0.75rem"
              mr={[0, '0.75rem']}
            />
            <span>Précédent</span>
          </Button>
        }
        nextLabel={
          <Button
            type="light"
            size="sm"
            onClick={nextPage}
            disabled={!hasNextPage}
          >
            <span>Suivant</span>
            <Icons
              name="arrow-right"
              width="1rem"
              height="1rem"
              ml={[0, '0.75rem']}
            />
          </Button>
        }
      />
    </PaginationContainer>
  )
}
export default Pagination
