import React, { Fragment } from 'react'
import { Icons } from 'components/theme'
import { Flex } from 'styles/globalStyle'

export const renderStars = (rating: number) => {
  const stars = [...Array(rating).keys()]
  const starsEmpty = [...Array(5 - rating).keys()]

  return (
    <Flex ml="0.5rem">
      {stars.map((nbr) => (
        <Fragment key={nbr}>
          <Icons name="star" width="1rem" height="1rem" />
        </Fragment>
      ))}
      {starsEmpty.map((nbr) => (
        <Fragment key={nbr}>
          <Icons name="star-empty" width="1rem" height="1rem" />
        </Fragment>
      ))}
    </Flex>
  )
}
